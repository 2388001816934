import React from "react";
import Layout from "../../components/Layout/Layout";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import LegalNav from "../../components/LegalNav/LegalNav";

const Content = () => {
  return (
    <>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cumque
        reprehenderit ad ratione quia adipisci nostrum maxime accusamus quaerat!
        Suscipit, hic.
      </p>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque
        voluptatibus obcaecati autem excepturi facere, aliquid vitae nobis
        deleniti ducimus. Suscipit asperiores consectetur sit ex placeat quod
        fugit nesciunt quis consequuntur.
      </p>
    </>
  );
};

const RefundPage = () => {
  return (
    <Layout>
      <SidebarLayout
        title='Refund Policy'
        nav={<LegalNav />}
        content={<Content />}
      />
    </Layout>
  );
};

export default RefundPage;
